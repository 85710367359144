<template>
  <div class="py-4 bg-white sm:px-6 lg:px-8 h-full">
    <Activities :activities="activities" />
  </div>
</template>

<script>
import Activities from "@/components/Activities";
import { useStore } from "vuex";

export default {
  components: {
    Activities,
  },
  setup() {
    const store = useStore();
    const activities = [
      {
        id: 1,
        type: "comment",
        document: true,
        person: { name: "Ashley James Solicitors", href: "#" },
        imageUrl:
          "https://www.ajsolicitors.com/website/wp-content/uploads/2014/11/logolatest2.jpg",
        comment: `Hi ${store.state.user.user.first_name}, I’ll be guiding you in your selling process.`,
        date: "Today 4:50 pm",
      },
      {
        id: 2,
        type: "assignment",
        comment: "Indy Deu assigned to your case.",
        person: { name: "Ashley James Solicitors", href: "#" },
        imageUrl:
          "https://www.ajsolicitors.com/website/wp-content/uploads/2014/11/logolatest2.jpg",
        assigned: { name: "Kristin Watson", href: "#" },
        date: "Today 4:35 pm",
      },
      {
        id: 4,
        type: "comment",
        person: { name: "Ashley James Solicitors", href: "#" },
        imageUrl:
          "https://www.ajsolicitors.com/website/wp-content/uploads/2014/11/logolatest2.jpg",
        comment: `Your application successfully submitted. We are assigning a solicitor to your case.`,
        date: "Today 4:50 pm",
      },
    ];
    return {
      activities,
    };
  },
};
</script>

<style>
</style>